<template>
  <model title="Empty" tag="空状态">
    <h3>示例</h3>
    <lh-empty>空空如也</lh-empty>
    <lh-collapse>
      <div slot="title">示例代码</div>
      <lh-code-editor v-model="code1" :readonly="true"></lh-code-editor>
    </lh-collapse>
    <h3>属性参考表</h3>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>size</lh-td>
        <lh-td>组件大小(图标大小)</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>200px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>padding</lh-td>
        <lh-td>组件内边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>20px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>color</lh-td>
        <lh-td>组件字体颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>#409EFF</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  name:"EmptyView",
  components: { Model },
  data(){
    return {
      code1:
        "<template>\n"+
        "  <lh-empty>空空如也</lh-empty>\n"+
        "</template>"
    }
  }

}
</script>

<style>

</style>